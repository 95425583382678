// InventorySession.js
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Peer from 'simple-peer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../contexts/AuthContext';
import { useInventory } from '../contexts/InventoryContext';
import { VIDEO_CONSTRAINTS, CODEC } from '../config/videoConfig';
import { jwtDecode } from 'jwt-decode';

const InventorySession = ({ 
  rack, 
  onBack, 
  updateQuantity,
  userContext,
}) => {
  // Hooks and Context
  const { socket, sendMessage } = useWebSocket();
  const { user } = useAuth();
  const { inventorySessionId, setInventorySessionId, lastActiveSessionId, setLastActiveSessionId } = useInventory();
  const [isSessionActive, setIsSessionActive] = useState(false);

  // State variables
  const [inventoryStarted, setInventoryStarted] = useState(false);
  const [quantities, setQuantities] = useState({});
  const [peer, setPeer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [iceConnected, setIceConnected] = useState(false);
  const [isTorchOn, setIsTorchOn] = useState(false);

  const [torchCapability, setTorchCapability] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const streamRef = useRef(null);

  const [showHeader, setShowHeader] = useState(true);

  // State variables for the updated products
  const [updatedProducts, setUpdatedProducts] = useState({});

  // Etats pour les horodatages et l'option de tri
  const [productUpdateTimestamps, setProductUpdateTimestamps] = useState({});
  const [shelfUpdateTimestamps, setShelfUpdateTimestamps] = useState({});
  const [sortOption, setSortOption] = useState('lastUpdate'); // Options: 'lastUpdate', 'alphabetical', 'objectCount'

  // Refs
  const videoRef = useRef(null);

  // Function to send the inventory update to the backend
  const sendInventoryUpdate = () => {

    // Get the session ID to use
    const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

    if (!sessionIdToUse) {
      console.error('No inventory session ID available');
      alert('Error: No active inventory session. Please start a new session.');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      // Obtenir user_id depuis userContext
      let userId = '';
      if (userContext && userContext.user_id) {
        userId = userContext.user_id;
      } else {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot send inventory update.');
        return;
      }

      // Construire l'objet inventoryData à partir des quantités actuelles
      const inventoryData = [];

      console.log('userId:', userId);
      console.log('rack:', rack);
      console.log('rack.storage_area_id:', rack.storage_area_id);

      rack.shelves.forEach(shelf => {
        console.log('shelf:', shelf);
        console.log('shelf.entity_id:', shelf.entity_id);
        shelf.expected_products.forEach(product => {
          console.log('product:', product);
          console.log('product.id:', product.id);
          const key = `${shelf.id}-${product.name}`;
          const quantity = quantities[key] || 0;
          inventoryData.push({
            shelf_id: shelf.id,
            product_name: product.name,
            product_id: product.id, // Assurez-vous que 'product.id' est disponible
            quantity: quantity.toString(),
            entity_id: shelf.entity_id, 
            storage_area_id: shelf.storage_area_id,
            rack_id: rack.id,
            updated_by: userId, // 'userId' obtenu précédemment
          });
        });
      });

      const message = {
        type: 'update_stock',
        user_id: userId,
        inventory_data: inventoryData,
        inventory_session_id: sessionIdToUse
      };

      console.log("Sending inventory update:", message);
      sendMessage(message);
      console.log("Inventory update sent to backend");
      // Vous pouvez masquer le bouton Send ici si nécessaire
      // document.getElementById('sendButton').classList.add('is-hidden');
    } else {
      console.error('WebSocket connection is not established.');
      alert('Unable to send inventory update. Please check your connection and try again.');
    }
  };

  // Function to truncate text
  const truncate = (text, length = 30) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  // Function to check if a stored session is valid
  const checkStoredSession = useCallback(async (sessionId) => {
    return new Promise((resolve) => {
      const messageHandler = (event) => {
        const data = JSON.parse(event.data);
        // console.log('checkStoredSession received:', data);
        if (['session_valid', 'session_not_found', 'session_expired'].includes(data.type)) {
          socket.removeEventListener('message', messageHandler);
          resolve(data.type === 'session_valid');
        }
      };
  
      socket.addEventListener('message', messageHandler);
  
      sendMessage({
        type: 'check_session',
        inventory_session_id: sessionId
      });
  
      // Timeout pour éviter d'attendre indéfiniment
      setTimeout(() => {
        socket.removeEventListener('message', messageHandler);
        resolve(false);
      }, 5000);
    });
  }, [socket, sendMessage]);

  // Function to toggle the torch
  const toggleTorch = async () => {
    // Inverser l'état de la torche
    setIsTorchOn(!isTorchOn);
  
    if (inventoryStarted && streamRef.current) {
      // Si la session est démarrée, appliquer les contraintes pour activer/désactiver la torche
      const track = streamRef.current.getVideoTracks()[0];
      const capabilities = track.getCapabilities();
  
      if (!capabilities.torch) {
        console.log("Torch is not supported on this device");
        return;
      }
  
      try {
        if (isIOS) {
          // Pour iOS, arrêter et redémarrer le flux avec la torche
          const currentConstraints = track.getConstraints();
          await track.stop();
          const newStream = await navigator.mediaDevices.getUserMedia({
            video: {
              ...currentConstraints,
              advanced: [{ torch: isTorchOn }]
            }
          });
          streamRef.current = newStream;
          if (videoRef.current) {
            videoRef.current.srcObject = newStream;
          }
        } else {
          // Pour les autres plateformes, utiliser applyConstraints
          await track.applyConstraints({
            advanced: [{ torch: isTorchOn }]
          });
        }
      } catch (err) {
        console.error("Error toggling torch:", err);
      }
    }
  };
  

  const turnOffTorch = async () => {
    if (!streamRef.current || !torchCapability) return;

    try {
      if (isIOS) {
        // Sur iOS, nous arrêtons simplement le flux
        streamRef.current.getVideoTracks().forEach(track => track.stop());
      } else {
        const track = streamRef.current.getVideoTracks()[0];
        await track.applyConstraints({
          advanced: [{ torch: false }]
        });
      }
      setIsTorchOn(false);
    } catch (err) {
      console.error("Error turning off torch:", err);
    }
  };

  // Function to start the video stream
  const startVideoStream = useCallback(async (sessionId, torchState) => {
    console.log("Starting video stream with session ID:", sessionId);
    setIsLoading(true);
    setIceConnected(false);
  
    try {
    
      // Set the torch state based on the torchOnStart state
      const constraints = {
        video: {
          ...VIDEO_CONSTRAINTS,
          advanced: isTorchOn ? [{ torch: true }] : []
        },
        audio: false
      };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;

      const videoTrack = stream.getVideoTracks()[0];
      const capabilities = videoTrack.getCapabilities();
      console.log("Video capabilities:", capabilities);

      // Vérifier si la torche est supportée
      if (capabilities.torch) {
        setTorchCapability(true);
        setIsTorchOn(torchState);
      } else {
        setTorchCapability(false);
        setIsTorchOn(false);
      }

      const newPeer = new Peer({
        initiator: true,
        trickle: false,
        stream: stream,
        config: { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }] }
      });
  
      newPeer.on('signal', data => {
        console.log("Peer signaling:", data);
        if (data.type === 'offer') {
          console.log("Sending offer to backend");
          if (sessionId) {
            console.log("Using inventory session ID:", sessionId);
            sendMessage({
              type: 'offer',
              sdp: data.sdp,
              inventory_session_id: sessionId
            });
          } else {
            console.error('No inventory session ID available');
            setIsLoading(false);
          }
        }
      });
  
      newPeer.on('connect', () => {
        console.log("Peer connection established");
      });
  
      newPeer.on('stream', stream => {
        console.log("Received stream from backend", stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
          videoRef.current.onloadedmetadata = () => {
            console.log("Video metadata loaded");
            const playPromise = videoRef.current.play();
            if (playPromise !== undefined) {
              playPromise.then(() => {
                console.log("Video playing");
                setVideoReady(true);
                if (iceConnected) {
                  setIsLoading(false);
                }
              }).catch(e => {
                console.error('Error playing video:', e);
                // Ne pas setIsLoading(false) ici, car la connexion ICE pourrait encore s'établir
              });
            }
          };
        }
      });

      newPeer._pc.oniceconnectionstatechange = () => {
        if (newPeer._pc.iceConnectionState === 'connected' || newPeer._pc.iceConnectionState === 'completed') {
          console.log('ICE connection established');
          setIceConnected(true);
          if (videoReady) {
            setIsLoading(false);
          }
        }
      };
  
      newPeer.on('error', err => {
        console.error('Peer error:', err);
        setIsLoading(false);
        alert('Error in video connection. Please try again.');
      });
  
      newPeer.on('close', () => {
        console.log("Peer connection closed");
        setIsLoading(false);
        setVideoReady(false);
      });
  
      setPeer(newPeer);

    } catch (error) {
      console.error('Error starting video stream:', error);
      alert('Error accessing the camera. Please check your device settings.');
      setIsLoading(false);
    }
  }, [sendMessage]);

  // Function to handle starting the inventory
  const handleStartInventory = async () => {
    console.log("handleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    await actualHandleStartInventory(isTorchOn);

  };

  // Function to handle the actual starting of the inventory
  const actualHandleStartInventory = useCallback(async (torchState) => {
    console.log("handleStartInventory called");
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }
  
    setIsLoading(true);
  
    try {
      const storedId = localStorage.getItem('inventorySessionId');
      if (storedId) {
        const isValid = await checkStoredSession(storedId);
        if (isValid) {
          console.log("Using stored valid session:", storedId);
          setInventorySessionId(storedId);
          setInventoryStarted(true);
          await startVideoStream(storedId, torchState);
          setIsLoading(false);
          return;
        } else {
          localStorage.removeItem('inventorySessionId');
        }
      }
  
      const decodedToken = jwtDecode(user.token);
      const sub = decodedToken.sub;

      // Obtenir user_id depuis userContext
      let userId = '';
      if (userContext && userContext.user_id) {
        userId = userContext.user_id;
      } else {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot start inventory session.');
        return;
      }
  
      console.log("Starting new inventory session");
      console.log('User token:', user.token ? `${user.token.slice(0, 4)}...${user.token.slice(-4)}` : 'No token');
      console.log('User sub:', sub ? `${sub.slice(0, 4)}...${sub.slice(-4)}` : 'No sub');
      console.log('User id:', user.id);
      console.log('User name:', user.name);

      // Request a new session ID from the backend
      const newSessionId = await new Promise((resolve, reject) => {
        sendMessage({
          type: 'start_inventory_session',
          sub: sub,
          user_id: userId,
          width: VIDEO_CONSTRAINTS.width.ideal,
          height: VIDEO_CONSTRAINTS.height.ideal,
          user_agent: navigator.userAgent,
          codec: CODEC,
          rack_id: rack.id 
        });
  
        const messageHandler = (event) => {
          const data = JSON.parse(event.data);
          if (data.type === 'start_inventory_session_response') {
            socket.removeEventListener('message', messageHandler);
            resolve(data.inventory_session_id);
          } else if (data.type === 'start_inventory_session_error') {
            socket.removeEventListener('message', messageHandler);
            reject(new Error(data.message));
          }
        };
  
        socket.addEventListener('message', messageHandler);
      });
  
      console.log("New inventory session created with ID:", newSessionId);
      setInventorySessionId(newSessionId);
      localStorage.setItem('inventorySessionId', newSessionId);
      localStorage.setItem('rackId', rack.id);
  
      // Wait for the state to update
      await new Promise(resolve => setTimeout(resolve, 0));
  
      // Start the video stream with the torch state
      await startVideoStream(newSessionId, torchState);
      setInventoryStarted(true);
    } catch (error) {
      console.error('Error starting inventory session:', error);
      setIsLoading(false);
      setInventoryStarted(false);
    }
  }, [user, sendMessage, startVideoStream, setInventorySessionId, rack]);
  

  // Function to handle stopping the inventory
  const handleStopInventory = async () => {
    console.log("Stopping inventory session");
    setIsSessionActive(false);

    // Éteindre la torche si elle est allumée
    if (isTorchOn) {
      await turnOffTorch();
      setIsTorchOn(false);
    }

    if (peer) {
      peer.destroy();
    }
    
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
    }

    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    setInventoryStarted(false);
    setVideoReady(false);
    setIsLoading(false);
    
    sendMessage({
      type: 'end_inventory_session',
      inventory_session_id: inventorySessionId
    });

    setLastActiveSessionId(inventorySessionId);
    setInventorySessionId(null);
    localStorage.removeItem('inventorySessionId');

    // Turn off the torch if it's on
    if (isTorchOn) {
      await turnOffTorch();
      setIsTorchOn(false);
    }
  };

  // Function to handle quantity changes
  const handleQuantityChange = (shelfId, productName, change) => {
    const key = `${shelfId}-${productName}`;
    
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev[key] || 0) + change);
      console.log(`Updating quantity for ${productName} on shelf ${shelfId}: ${prev[key]} -> ${newQuantity}`);
      
      // Envoyer le message au backend
      if (socket && socket.readyState === WebSocket.OPEN) {

        const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

        const message = {
          type: 'adjusted_quantity',
          data: {
            shelf_id: shelfId.toString(),
            product_name: productName,
            adjusted_quantity: newQuantity.toString(),
            adjustment_reason: change > 0 ? "User increased quantity" : "User decreased quantity",
            inventory_session_id: sessionIdToUse
          }
        };
  
        // Log du message envoyé
        console.log('Sending adjusted_quantity message to backend:', message);
  
        sendMessage(message);
      } else {
        console.error('WebSocket connection is not established.');
      }
  
      return {
        ...prev,
        [key]: newQuantity
      };
    });
  
    // Supprimer l'appel à updateQuantity
    // updateQuantity(shelfId, productName, change);
  };

  useEffect(() => {
    if (rack && rack.shelves) {
      console.log("Rack:", rack);
      console.log("Shelves:", rack.shelves);
    } else {
      console.error("Rack or shelves are undefined");
    }
  }, [rack]);
  

  // Nettoyage du peer
  useEffect(() => {
    // Pas de code ici, nous utilisons cet effet uniquement pour le nettoyage
  
    return () => {
      // Nettoyage au démontage du composant
      if (peer) {
        peer.destroy();
      }
    };
  }, []); 

  // Effect to initialize quantities
  useEffect(() => {
    const initialQuantities = {};
    rack.shelves.forEach(shelf => {
      shelf.expected_products.forEach(product => {
        initialQuantities[`${shelf.id}-${product.name}`] = 0; // Initialisation à zéro
      });
    });
    setQuantities(initialQuantities);

    const headerSection = document.querySelector('.section.has-background-white');
    if (headerSection) {
      headerSection.classList.add('is-hidden');
    }

    return () => {
      if (headerSection) {
        headerSection.classList.remove('is-hidden');
      }
    };
  }, []); // Exécuter seulement au montage

  // Effect to handle WebSocket messages
  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received message:", data);
        switch(data.type) {

          case 'session_valid':
            // Déjà géré dans checkStoredSession, pas d'action nécessaire ici
            break;

          case 'session_not_found':
          case 'session_expired':
            // Déjà géré dans checkStoredSession, pas d'action nécessaire ici
            break;
          
          case 'start_inventory_session_response':
            console.log('Received inventory session ID:', data.inventory_session_id);
            setInventorySessionId(data.inventory_session_id);
            localStorage.setItem('inventorySessionId', data.inventory_session_id);
            break;

          case 'start_inventory_session_error':
            console.error('Error starting inventory session:', data.message);
            setIsLoading(false);
            // Display an error message to the user
            break;

          case 'answer':
            console.log("Received answer from backend", data);
            if (peer) {
              try {
                peer.signal(data);
              } catch (error) {
                console.error("Error signaling peer with answer:", error);
              }
            } else {
              console.warn("Received answer but peer is not initialized");
            }
            break;

            case 'update_stock_response':
              console.log('Inventory update acknowledged by backend');
              alert('Inventory update sent successfully.');
              onBack(); // Revenir à l'écran précédent
              break;

            case 'stock_update_error':
              console.error('Error updating inventory:', data.message);
              alert('Error updating inventory: ' + data.message);
              break;
    
            case 'update_stock_error':
              console.error('Error updating inventory:', data.message);
              alert('Error updating inventory: ' + data.message);
              break;

          case 'inventory':
            console.log('Received inventory data:', JSON.stringify(data, null, 2));
            try {
              if (data.data) {
                Object.entries(data.data).forEach(([shelfId, shelfData]) => {
                  if (shelfData.products && typeof shelfData.products === 'object') {
                    let shelfUpdated = false; // Pour savoir si l'étagère a été mise à jour
                    Object.entries(shelfData.products).forEach(([productName, productData]) => {
                      const key = `${shelfId}-${productName}`;
                      setQuantities(prev => {
                        const oldCount = prev[key] || 0;
                        const newCount = productData.count;
                        if (oldCount !== newCount) {
                          // Ajouter le produit à updatedProducts pour le clignotement
                          setUpdatedProducts(prevUpdated => ({
                            ...prevUpdated,
                            [key]: true
                          }));
          
                          // Supprimer le clignotement après 500ms
                          setTimeout(() => {
                            setUpdatedProducts(prevUpdated => {
                              const { [key]: _, ...rest } = prevUpdated;
                              return rest;
                            });
                          }, 500);

                          // Mettre à jour l'horodatage du produit
                          setProductUpdateTimestamps(prevTimestamps => ({
                            ...prevTimestamps,
                            [key]: Date.now()
                          }));

                          shelfUpdated = true; // Marquer l'étagère comme mise à jour

                        }
                        return {
                          ...prev,
                          [key]: newCount
                        };
                      });
                    });

                    if (shelfUpdated) {
                      // Mettre à jour l'horodatage de l'étagère
                      setShelfUpdateTimestamps(prevTimestamps => ({
                        ...prevTimestamps,
                        [shelfId]: Date.now()
                      }));
                    }

                  } else {
                    console.error('Unexpected products structure for shelf:', shelfId, shelfData);
                  }
                });
              }
            } catch (error) {
              console.error('Error processing inventory data:', error);
            }
            break;

          default:
            console.log('Unhandled message type:', data.type);
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket, setInventorySessionId, peer, updateQuantity, onBack]);

  // Utilisation de useMemo pour mémoriser les étagères triées
  const sortedShelves = useMemo(() => {
    if (!rack || !rack.shelves) return [];

    const shelvesCopy = [...rack.shelves];

    switch (sortOption) {
      case 'lastUpdate':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelfUpdateTimestamps[shelf.id] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue); // Descendant
      case 'alphabetical':
        return shelvesCopy.sort((a, b) => a.name.localeCompare(b.name));
      case 'objectCount':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelf.expected_products.reduce((sum, p) => {
              const key = `${shelf.id}-${p.name}`;
              return sum + (quantities[key] || 0);
            }, 0)
          }))
          .sort((a, b) => b.sortValue - a.sortValue); // Descendant
      default:
        return shelvesCopy;
    }
  }, [rack, sortOption, shelfUpdateTimestamps, quantities]);

  // Fonction pour trier les étagères
  const getSortedShelves = () => {
    const shelves = rack && rack.shelves ? [...rack.shelves] : [];

    switch (sortOption) {
      case 'lastUpdate':
        return shelves.sort((a, b) => {
          const timestampA = shelfUpdateTimestamps[a.id] || 0;
          const timestampB = shelfUpdateTimestamps[b.id] || 0;
          return timestampB - timestampA; // Ordre décroissant
        });
      case 'alphabetical':
        return shelves.sort((a, b) => a.name.localeCompare(b.name));
      case 'objectCount':
        return shelves.sort((a, b) => {
          const countA = a.expected_products.reduce((sum, p) => {
            const key = `${a.id}-${p.name}`;
            return sum + (quantities[key] || 0);
          }, 0);
          const countB = b.expected_products.reduce((sum, p) => {
            const key = `${b.id}-${p.name}`;
            return sum + (quantities[key] || 0);
          }, 0);
          return countB - countA; // Ordre décroissant
        });
      default:
        return shelves;
    }
  };

  // Fonction pour trier les produits
  // Utilisation de useCallback pour trier les produits
  const getSortedProducts = useCallback((shelf) => {
    if (!shelf.expected_products) return [];

    const productsCopy = [...shelf.expected_products];

    switch (sortOption) {
      case 'lastUpdate':
        return productsCopy
          .map(product => ({
            ...product,
            sortValue: productUpdateTimestamps[`${shelf.id}-${product.name}`] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue); // Descendant
      case 'alphabetical':
        return productsCopy.sort((a, b) => a.name.localeCompare(b.name));
      case 'objectCount':
        return productsCopy.sort((a, b) => {
          const countA = quantities[`${shelf.id}-${a.name}`] || 0;
          const countB = quantities[`${shelf.id}-${b.name}`] || 0;
          return countB - countA; // Descendant
        });
      default:
        return productsCopy;
    }
  }, [sortOption, productUpdateTimestamps, quantities]);

  // Fonction pour vérifier la session stockée
  const verifyStoredSession = useCallback(async () => {
    const storedId = localStorage.getItem('inventorySessionId');
    if (storedId) {
      // Vérifier si le rack_id est stocké
      const storedRackId = localStorage.getItem('rackId');
      if (!storedRackId) {
        console.log("No rackId stored with session. Clearing stored session.");
        localStorage.removeItem('inventorySessionId');
        localStorage.removeItem('rackId');
        return;
      }

      console.log("Retrieved stored inventory session ID:", storedId);
      const isValid = await checkStoredSession(storedId);
      if (isValid) {
        setInventorySessionId(storedId);
        setInventoryStarted(true);
  
        // Démarrer le flux vidéo avec l'état de la torche par défaut
        await startVideoStream(storedId, false);
      } else {
        console.log("Stored session is no longer valid. Clearing local data.");
        setInventorySessionId(null);
        setInventoryStarted(false);
        localStorage.removeItem('inventorySessionId');
      }
    }
  }, [checkStoredSession, startVideoStream, setInventorySessionId]);

  // Effect pour vérifier la session stockée
  useEffect(() => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      verifyStoredSession();
    }
  }, [socket, verifyStoredSession]);

  // Effect to detect if the device is iOS
  useEffect(() => {
    // Détecte si l'appareil est iOS
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  // Effect pour définir l'ID de session actif
  useEffect(() => {
    if (inventorySessionId) {
      setIsSessionActive(true);
      setLastActiveSessionId(inventorySessionId);
    }
  }, [inventorySessionId, setLastActiveSessionId]);

   // Adjust video container aspect ratio when video is ready
  //  useEffect(() => {
  //   if (videoRef.current) {
  //     videoRef.current.onloadedmetadata = () => {
  //       const videoWidth = videoRef.current.videoWidth;
  //       const videoHeight = videoRef.current.videoHeight;
  //       const aspectRatio = (videoHeight / videoWidth) * 100;
  //       const videoContainer = document.getElementById('videoContainer');
  //       if (videoContainer) {
  //         videoContainer.style.paddingBottom = `${aspectRatio}%`;
  //       }
  //     };
  //   }
  // }, [videoReady]);

  // Render
  return (
    <div className="inventory-session">
      {showHeader && (
        <div className="inventory-header columns is-mobile is-gapless mb-4">
          <div className="column is-narrow">
            <button onClick={onBack} className="button is-small is-light">
              <span className="icon">
                <i className="fas fa-chevron-left"></i>
              </span>
            </button>
          </div>
          <div className="column">
            <h2 className="title is-4 has-text-centered mb-0">{rack.name} Inventory</h2>
          </div>
        </div>
      )}

      <div id="videoContainer">
        {isLoading && (
          <div className="spinner-container">
            {/* Votre SVG de spinner */}
          </div>
        )}
        <video 
          id="remoteVideo" 
          ref={videoRef} 
          className={videoReady ? '' : 'is-hidden'} 
          autoPlay 
          playsInline 
          muted 
        />
      </div>
      
      {/* Section des boutons alignés sur la même ligne */}
      <div className="buttons are-small is-centered mb-4">

        {/* Bouton de tri A-Z */}
        <button 
          className={`button is-small ${sortOption === 'alphabetical' ? 'is-info' : 'is-light'}`} 
          onClick={() => { 
            console.log('Sorting by alphabetical'); 
            setSortOption('alphabetical'); 
          }}
        >
          <span className="icon">
            <i className="fas fa-sort-alpha-down"></i>
          </span>
          <span>A-Z</span>
        </button>
        
        {/* Bouton de tri Qty */}
        <button 
          className={`button is-small ${sortOption === 'objectCount' ? 'is-info' : 'is-light'}`} 
          onClick={() => { 
            console.log('Sorting by quantity'); 
            setSortOption('objectCount'); 
          }}
        >
          <span className="icon">
            <i className="fas fa-sort-numeric-down"></i>
          </span>
          <span>Qty</span>
        </button>

        {/* Bouton de tri Last Update */}
        <button 
          className={`button is-small ${sortOption === 'lastUpdate' ? 'is-info' : 'is-light'}`} 
          onClick={() => { 
            console.log('Sorting by last update'); 
            setSortOption('lastUpdate'); 
          }}
        >
          <span className="icon">
            <i className="fas fa-clock"></i>
          </span>
          <span>Last</span>
        </button>

        {/* Bouton Torch */}
        <button
          onClick={toggleTorch}
          className={`button is-small ${isTorchOn ? 'is-warning' : 'is-light'}`}
          disabled={!inventoryStarted}
        >
          <span className="icon">
            <i className={`${isTorchOn ? 'fas' : 'far'} fa-lightbulb`}></i>
          </span>
        </button>
        
        {/* Bouton Start/Stop */}
        {!inventoryStarted ? (
          <button
            onClick={handleStartInventory}
            className="button is-small is-success"
          >
            <span className="icon"><i className="fas fa-play"></i></span>
            <span>Start</span>
          </button>
        ) : (
          <button
            onClick={handleStopInventory}
            className="button is-small is-danger"
          >
            <span className="icon"><i className="fas fa-stop"></i></span>
            <span>Stop</span>
          </button>
        )}

      </div>

      {/* Conteneur des étagères */}
      <div className="shelves-container mt-4">
        {sortedShelves.map(shelf => (
          <div key={shelf.id} className="mb-4">
            <h3 className="title is-5 mb-2 pb-2 has-border-bottom">{shelf.name}</h3>
            {getSortedProducts(shelf).map(product => {
              const key = `${shelf.id}-${product.name}`;
              return (
                <div key={product.id} className="field has-addons mb-2">
                  <div className="control is-expanded has-text-left">
                    <p className="is-size-6 has-text-weight-semibold mb-1">
                      {truncate(product.description || product.name, 50)}
                    </p>
                    <p className="is-size-7 has-text-grey">{product.name}</p>
                  </div>
                  <div className="control">
                    <button className="button is-primary is-small" onClick={() => handleQuantityChange(shelf.id, product.name, -1)}>-</button>
                  </div>
                  <div className="control">
                    <input 
                      className={`input is-small has-text-centered count-cell ${updatedProducts[key] ? 'is-blinking' : ''}`}
                      type="number"
                      value={quantities[key] || 0}
                      onChange={(e) => handleQuantityChange(shelf.id, product.name, parseInt(e.target.value) - (quantities[key] || 0))}
                    />    
                  </div>
                  <div className="control">
                    <button className="button is-primary is-small" onClick={() => handleQuantityChange(shelf.id, product.name, 1)}>+</button>
                  </div>
                </div>
              );
            })}
          </div>
        ))}
      </div>

      <button 
        className="button is-success is-fullwidth mt-4"
        onClick={sendInventoryUpdate}
      >
        <span className="icon"><i className="fas fa-paper-plane"></i></span>
        <span>Send</span>
      </button>
    </div>
  );
};

export default InventorySession;