// App.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import './App.css';
import 'bulma/css/bulma.min.css';
import { WebSocketProvider, useWebSocket } from './contexts/WebSocketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { getKeycloakInstance } from './services/auth';
import { InventoryProvider } from './contexts/InventoryContext';
import { VIDEO_CONSTRAINTS, CODEC } from './config/videoConfig';
import Navbar from './components/Navbar';
import InventoryNavigation from './components/InventoryNavigation';
import VideoComponent from './components/VideoComponent';

const App = () => {
  const { user, authenticated } = useAuth();
  const [userContext, setUserContext] = useState(null);
  const [inventorySessionId, setInventorySessionId] = useState(null);

  // Initialiser l'état en mode 'rack' et définir selectedEntity à null
  const [state, setState] = useState({
    mode: 'rack',
    selectedEntity: null,
    zone: null,
    rack: null,
    shelf: null,
    showSelectMessage: false, // Masquer le message de sélection du mode
    showZoneSelection: true // Afficher la sélection de zone directement
  });

  const { socket, connectWebSocket, sendMessage } = useWebSocket();
  const userContextAttemptsRef = useRef(0);
  const MAX_USER_CONTEXT_ATTEMPTS = 3;

  useEffect(() => {
    if (authenticated && user && !socket) {
      console.log('Connecting WebSocket with token:', user.token);
      connectWebSocket(user.token);
    }
  }, [authenticated, user, connectWebSocket, socket]);

  const getUserContext = useCallback(() => {
    console.log('getUserContext called', { 
      attempts: userContextAttemptsRef.current, 
      maxAttempts: MAX_USER_CONTEXT_ATTEMPTS, 
      userContext: !!userContext,
      socketReady: socket && socket.readyState === WebSocket.OPEN,
      userAuthenticated: !!user && !!user.token
    });

    if (userContextAttemptsRef.current >= MAX_USER_CONTEXT_ATTEMPTS || userContext) {
      console.log('Max attempts reached or user context already set');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN && user && user.token) {
      console.log('Requesting user context...');
      try {
        const decodedToken = jwtDecode(user.token);
        const sub = decodedToken.sub;
        console.log('Decoded token sub:', sub);
        sendMessage({
          type: 'get_user_context',
          sub: sub
        });
        userContextAttemptsRef.current += 1;
      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    } else {
      console.error('WebSocket is not open or user is not authenticated. Cannot request user context.', {
        socketOpen: socket && socket.readyState === WebSocket.OPEN,
        userAuthenticated: !!user && !!user.token
      });
    }
  }, [socket, user, sendMessage, userContext]);

  useEffect(() => {
    if (authenticated && socket && !userContext) {
      const storedContext = localStorage.getItem('userContext');
      if (storedContext) {
        console.log('Using stored user context');
        setUserContext(JSON.parse(storedContext));
      } else {
        console.log('No stored user context found, requesting from server');
        getUserContext();
      }
    }
  }, [authenticated, socket, getUserContext, userContext]);

  useEffect(() => {
    if (socket) {
      const handleMessage = (event) => {
        const message = JSON.parse(event.data);
        console.log('Received WebSocket message:', message);
        switch (message.type) {
          case 'user_context':
            console.log('Received user context:', message.data);
            setUserContext(message.data);
            localStorage.setItem('userContext', JSON.stringify(message.data));
            break;
          case 'start_inventory_session_response':
            console.log('Received inventory session ID:', message.inventory_session_id);
            setInventorySessionId(message.inventory_session_id);
            break;
          case 'user_context_error':
            console.error('Error getting user context:', message.error);
            break;
          default:
            console.log('Unhandled message type:', message.type, message);
            break;
        }
      };

      socket.addEventListener('message', handleMessage);

      return () => {
        socket.removeEventListener('message', handleMessage);
      };
    }
  }, [socket]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('state') === 'logged-out') {
      // User has been redirected back after logout
      window.history.replaceState(null, '', window.location.pathname);
      // Optionally, you can force a page reload here
      // window.location.reload();
    }
  }, []);

  const startInventorySession = async () => {
    console.log("Starting inventory session - startInventorySession()");

    if (socket && socket.readyState === WebSocket.OPEN && user) {

      const stream = await navigator.mediaDevices.getUserMedia({ video: VIDEO_CONSTRAINTS });
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();

      console.log(`Video dimensions: ${settings.width}x${settings.height}`);
      console.log(`Video orientation: ${settings.width > settings.height ? 'Landscape' : 'Portrait'}`);

      sendMessage({
        type: 'start_inventory_session',
        user_id: user.id,
        width: settings.width,
        height: settings.height,
        user_agent: navigator.userAgent,
        codec: 'vp9',
        token: user.token,
        user_context: userContext,
        rack_id: state.rack ? state.rack.id : null
      });
    }
  };

  const handleModeSelect = (mode) => {
    setState(prevState => ({
      ...prevState,
      mode,
      selectedEntity: null,
      zone: null,
      rack: null,
      shelf: null,
      showSelectMessage: mode !== 'rack',
      showZoneSelection: mode === 'rack'
    }));
  };

  const updateBreadcrumb = useCallback(() => {
    const breadcrumbItems = [];
  
    // Fonction pour tronquer le texte
    const truncate = (text, length = 10) => {
      return text.length > length ? text.substring(0, length) + '...' : text;
    };
  
    // Home - Retour à la sélection d'entité
    breadcrumbItems.push({
      text: 'Home',
      icon: 'fa-home',
      onClick: () => setState(prevState => ({
        ...prevState,
        mode: 'rack',
        selectedEntity: null,
        zone: null,
        rack: null,
        shelf: null,
        showSelectMessage: false, // Ou true selon votre logique
        showZoneSelection: true
      })),
      isHome: true
    });
  
    // Entity
    if (state.selectedEntity) {
      breadcrumbItems.push({
        text: truncate(state.selectedEntity.name),
        icon: 'fa-building',
        onClick: () => setState(prev => ({ ...prev, zone: null, rack: null, shelf: null }))
      });
    }
  
    // Storage Area
    if (state.zone) {
      breadcrumbItems.push({
        text: truncate(state.zone.name),
        icon: 'fa-warehouse',
        onClick: () => setState(prev => ({ ...prev, rack: null, shelf: null }))
      });
    }
  
    // Rack
    if (state.rack) {
      breadcrumbItems.push({
        text: truncate(state.rack.name),
        icon: 'fa-th',
        onClick: () => setState(prev => ({ ...prev, shelf: null }))
      });
    }
  
    // Shelf (si applicable)
    if (state.shelf) {
      breadcrumbItems.push({ text: truncate(state.shelf.name), icon: 'fa-layer-group' });
    }
    
    return (
      <nav className="breadcrumb has-succeeds-separator is-small" aria-label="breadcrumbs">
        <ul className="is-flex-wrap-nowrap">
          {breadcrumbItems.map((item, index) => (
            <li key={index} className={index === breadcrumbItems.length - 1 ? 'is-active' : ''}>
              {item.onClick ? (
                <button onClick={item.onClick} className="breadcrumb-link button is-text p-1">
                  <span className="icon is-small">
                    <i className={`fas ${item.icon}`} aria-hidden="true"></i>
                  </span>
                  {/* Supprimer 'is-hidden-mobile' */}
                  <span className="breadcrumb-text">{item.text}</span>
                </button>
              ) : (
                <span className="p-1">
                  <span className="icon is-small">
                    <i className={`fas ${item.icon}`} aria-hidden="true"></i>
                  </span>
                  {/* Supprimer 'is-hidden-mobile' */}
                  <span className="breadcrumb-text">{item.text}</span>
                </span>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  }, [state]);
  

  useEffect(() => {
    updateBreadcrumb();
  }, [updateBreadcrumb]);

  if (!authenticated) {
    const keycloak = getKeycloakInstance();
    if (keycloak) {
      keycloak.login();
    }
    return null;
  }

  return (
    <div className="App">
      <section className="section has-background-white">
        <div>
          {/* <h1 className="title is-4" id="title">Welcome {user ? user.name : 'User'}</h1>
          
         <div className="columns is-mobile is-multiline is-centered">
            {['periodic', 'product', 'rack', 'free'].map((mode) => (
              <div key={mode} className="column is-one-quarter-mobile">
                <div className={`card ${state.mode === mode ? 'is-selected' : ''}`} onClick={() => handleModeSelect(mode)}>
                  <div className="card-content">
                    <span className="icon card-icon">
                      <i className={`fas fa-${mode === 'periodic' ? 'calendar-alt' : mode === 'product' ? 'box-open' : mode === 'rack' ? 'qrcode' : 'binoculars'}`}></i>
                    </span>
                    <p className="title">{mode.charAt(0).toUpperCase() + mode.slice(1)}</p>
                  </div>
                </div>
              </div>
            ))}
          </div> */}
          {updateBreadcrumb()}
        </div>
      </section>

      {/* Masquer le message de sélection du mode */}
      <div id="selectMessage" className={`section ${state.showSelectMessage ? '' : 'is-hidden'}`}>
        <div>
          <div className="has-text-centered">
            <span className="icon">
              <i className="fas fa-chevron-up"></i>
            </span>
            <p>Select the inventory mode you want</p>
          </div>
        </div>
      </div>


      <div id="content" className="section">
        {userContext ? (
          state.showZoneSelection && (
            <InventoryNavigation 
              userContext={userContext} 
              state={state} 
              setState={setState}
              startInventorySession={startInventorySession}
              inventorySessionId={inventorySessionId}
            />
          )
        ) : (
          <div>Loading user context...</div>
        )}
      </div>
      <Navbar />
      {inventorySessionId && <VideoComponent inventorySessionId={inventorySessionId} />}
    </div>
  );
};

const AppWithProviders = () => (
  <AuthProvider>
    <WebSocketProvider>
      <InventoryProvider>
        <App />
      </InventoryProvider>
    </WebSocketProvider>
  </AuthProvider>
);

export default AppWithProviders;